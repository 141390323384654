.TeamDetails {
  display: flex;
  flex-direction: column;

  color: #2f2f2f;
  background-color: white;

  width: 90%;
  height: 100%;

  max-width: 450px;
  padding: 10px;
  margin: 0 auto 30px auto;

  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);

  button {
    margin-right: auto;
  }

  span {
    text-transform: uppercase;
    font-size: 12px;
    margin: 10px 0 0 0;
    color: rgba(0, 0, 0, 0.5);
  }

  p {
    max-height: 200px;
    overflow: auto;
    font-size: 14px;
    margin: 0;
    padding: 10px 0;
  }
}
